import { ADD_CHECK_LIST, ADD_SYSTEM, ADD_SYSTEM_MEASUREMENT, DELETE_SYSTEM, EDIT_SYSTEM, GET_CHECK_LIST_BY_SYSTEM_ID, GET_SYSTEMS_BY_ORDER_ID, GET_SYSTEMS_CHECK_LIST_MOBILE, GET_SYSTEMS_CHECK_LIST_TABLET, GET_SYSTEMS_FOR_ACCORDION, MOVE_SYSTEM_ROW, REMOVE_SYSTEM_FROM_SYSTEMS_OPENING_PATH, SELECTED_SYSTEM_BY_SYSTEM_ID, SET_SYSTEMS_FOR_ACCORDION, SET_SYSTEM_FOR_SYSTEMS_OPENING_PATH, SHOW_SYSTEMS_POP_UP, UPDATE_SYSTEM_CHECKLIST } from "../../constants/customer/systemConstants";
import { mobileSystemsCheckList, systemsAccordion, tabletSystemsCheckList } from "../../constants/staticeData/systemsList";
import { get,} from "lodash";
 
const initialState = {
    systemsCheckListMobile: mobileSystemsCheckList,
    systemsCheckListTablet: tabletSystemsCheckList,
    systemsAllData: [],
    originSystemData: [],
    // systemsForAccordion: systemsAccordion,
    systemsForAccordion: [],
    singleSystem: {},
    singleSystemProgress: 0,
    systemsOpeningPath: [],
    systemMeasurement: null,

    isShowSystemsPopup: false
}


// select system to delete and edit
function getSelectedSystemBySystemId(systems, systemId) {
    const selectedSystemSystemObj = {};
    for (let i = 0; i < systems?.length; i++) {
        const _systems = systems[i].systems;
        for (let _system of _systems) {
            if (_system.system_id === systemId) {
                return _system;
            }
        }
    }

    return selectedSystemSystemObj;
}


// delete system part
function deleteSystemFromSelectedLayerSystems(systemsOfLayer, systemId, parentSystemId) {
    const { systems, layer } = systemsOfLayer;
    const systemsAfterDelete = systems.map((_systems) =>{
        const __systems = _systems?.systems;
        const filterAndDeleteSystemBySystemId = __systems.filter((__system) => __system.system_id !== systemId);
        return {
            parent_system_id: _systems?.parent_system_id, 
            systems: filterAndDeleteSystemBySystemId 
        }
    })
    const data = { layer, systems: systemsAfterDelete }
    return data;
}


// edit system
function editSystemFromSelectedLayerSystems(systemsOfLayer, editSystemInformation){
    const { systems, layer } = systemsOfLayer;
    const { systemId, parentId, systemName, locationDescription, floor } = editSystemInformation;

    const systemsAfterEdit = systems.map((_systems) => {
        const __systems = _systems?.systems;
        const filterAndEditSystemBySystemId = __systems.map((__system) => {
            if (__system.system_id !== systemId){
                return __system;
            }else{
                const { system_name, location_description, location_floor } = __system;
                const editedSystemData = {
                    ...__system,
                    system_name: systemName ? (systemName?.name || systemName) : system_name,
                    location_description: locationDescription ? locationDescription : location_description,
                    location_floor: floor ? floor : location_floor
                }
                return editedSystemData;
            }
        });
        return {
            parent_system_id: _systems?.parent_system_id,
            systems: filterAndEditSystemBySystemId
        }
    })
    const data = { layer, systems: systemsAfterEdit }
    return data;
}




function newPushNewSystemToSystemsForAccordion(tree, newSystemData) {
   
    const { parentId, floor, locationDescription, systemName, layer, order_id } = newSystemData;
    const systemId = "id" + Math.random().toString(16).slice(2);

    const dataObject = {
        actual_system_id: systemId,
        actual_system_name: "",
        check_list: null,
        layer: layer,
        location_description: locationDescription,
        location_floor: floor,
        order_id: order_id,
        parent_system_id: parentId,
        progress: 0,
        system_id: systemId,
        system_name: systemName,
        template_system_id: "6073df6ee206baa7365fcbbb",
        _id: systemId,
    };
    const newSystemDataToSet = {
        parent_system_id: parentId,
        systems: [dataObject]
    }

    const systemIdTemplateIdMap = {}
    for (const treeNode of tree) {
        const {
            systems
        } = treeNode;

        for (const system of systems) {
            const systemList = system.systems;
            for (const item of systemList) {
                const {
                    template_system_id,
                    system_id,
                } = item;

                systemIdTemplateIdMap[system_id] = template_system_id;
            }
        }
    }


    
    let systemByLayerWhereToAddNewSystem = tree[layer-1] ? tree[layer-1]?.systems : [];

    if (!systemByLayerWhereToAddNewSystem.length) {
       
        systemByLayerWhereToAddNewSystem.push(newSystemDataToSet)
        tree.push({ layer: layer, systems: systemByLayerWhereToAddNewSystem });
    } else if(parentId){
        let found = false;
       
        for (let i = 0; i < systemByLayerWhereToAddNewSystem.length; i++) {
            const _system = systemByLayerWhereToAddNewSystem[i];
           

            const parentSystemIdToMatch = parentId in systemIdTemplateIdMap ? systemIdTemplateIdMap[parentId] : parentId
           
            if (_system.parent_system_id === parentId){
               
                _system.systems.push(dataObject);
                found = true;
                break;
            }
           
        }
        if (!found) {
            systemByLayerWhereToAddNewSystem.push({
                parent_system_id: parentId,
                systems: [
                    {...dataObject},
                ]
            })
        }  
    } else{
       
        systemByLayerWhereToAddNewSystem.push(newSystemDataToSet)
    }
   
    // systemByLayerWhereToAddNewSystem[0].systems.push(dataObject)
   

    console.log("tree", tree)
    return tree;
}





function getColorCodeByLayer(layer) {
    let color;
    let background;
    switch (layer) {
        case 1:
            color = "#81B03E";
            background = "#F2F5ED";
            break;
        case 2:
            color = "#3EB0A7";
            background = "#E3F3F2";
            break;
        case 3:
            color = "#BD1657";
            background = "#FCEEF3";
            break;
        case 4:
            color = "#3E54B0";
            background = "#E8EBF4";
            break;
        case 5:
            color = "#C532C4";
            background = "#FDEEFD";
            break;
        default:
            color = "#81B03E";
            background = "#F2F5ED";
            break;
    }
    return {
        color,
        background,
    }
}

// formatting the date to required from for UI 
function formattedDataByLayer(systemItem) {
    const formattedData = systemItem.systems[0]?.systems.map((systemInfo) => {
        const { color, background } = getColorCodeByLayer(systemItem.layer);
        return {
            data: [{
                hasMore: false,
                progress: systemInfo.progress,
                parentId: systemInfo.parent_system_id,
                systemId: systemInfo.system_id,
                checkList: systemInfo.check_list,
                layer: systemItem.layer,
                items: [
                    {
                        name: "System name",
                        details: systemInfo.system_name,
                        nameColor: color,
                        nameBackground: background
                    },
                    {
                        name: "Location description",
                        details: systemInfo.location_description,
                        nameColor: color,
                        nameBackground: background
                    },
                    {
                        name: "Floor",
                        details: systemInfo.location_floor,
                        nameColor: color,
                        nameBackground: background
                    },
                ]
            }]
        }
    })
    return formattedData;
}
function refactoringData(data) {
    return data && data.map((systemItem) => {
        if (systemItem.layer === 1) {
            const layerOneData = formattedDataByLayer(systemItem);
            return layerOneData;
        }
        if (systemItem.layer === 2) {
            const layerTwoData = formattedDataByLayer(systemItem);
            return layerTwoData;
        }
        if (systemItem.layer === 3) {
            const layerThreeData = formattedDataByLayer(systemItem);
            return layerThreeData;
        }
        if (systemItem.layer === 4) {
            const layerFourData = formattedDataByLayer(systemItem);
            return layerFourData;
        }
    })
}


function getSystemForAccordion(state, action) {
    let systemsData = action.payload && action.payload?.[0]?.systems?.[0]?.systems?.[0];
    if (!systemsData) return {
        ...state,
        systemsAllData: [],
        systemsForAccordion: [], 
    };

    const tree = [];
    const systemsApiData = action.payload;
    const parentIdMap = {};
    for (const system of systemsApiData) {
        const {
            layer,
            systems,
        } = system;
        const { color, background } = getColorCodeByLayer(layer)
        for (const _system of systems) {
            const {
                parent_system_id,
                systems: _systems,
            } = _system;
            if (parent_system_id == null) {
                for (const __system of _systems) {
                
                    const systemIdForMap = 'template_system_id' in __system ? __system.template_system_id : __system.system_id;
                    parentIdMap[systemIdForMap] = `[${tree.length}].data.[0].data`; 
                    tree.push({data: [{
                        hasMore: true,
                        progress: __system.progress,
                        parentId: __system.parent_system_id,
                        systemId: __system.system_id,
                        checkList: __system.check_list,
                        orginalSystemId: __system.system_id,
                        layer,
                        items: [
                            {
                                name: "System name",
                                details: __system.system_name,
                                nameColor: color,
                                nameBackground: background
                            },
                            {
                                name: "Location description",
                                details: __system.location_description,
                                nameColor: color,
                                nameBackground: background
                            },
                            {
                                name: "Floor",
                                details: __system.location_floor,
                                nameColor: color,
                                nameBackground: background
                            },
                        ],
                        data:[]
                    }]
                    });
                }
            } else if (parent_system_id in parentIdMap) {
                const idxInfo = parentIdMap[parent_system_id];
                for (const __system of _systems) {
        
                    const systemIdForMap = 'template_system_id' in __system ? __system.template_system_id : __system.system_id;
                    parentIdMap[systemIdForMap] = `${idxInfo}.[${get(tree, idxInfo).length}].data`; 
                    get(tree, idxInfo).push({
                        hasMore: true,
                        progress: __system.progress,
                        parentId: __system.parent_system_id,
                        systemId: __system.system_id,
                        orginalSystemId: __system.system_id,
                        checkList: __system.check_list,
                        layer,
                        items: [
                            {
                                name: "System name",
                                details: __system.system_name,
                                nameColor: color,
                                nameBackground: background
                            },
                            {
                                name: "Location description",
                                details: __system.location_description,
                                nameColor: color,
                                nameBackground: background
                            },
                            {
                                name: "Floor",
                                details: __system.location_floor,
                                nameColor: color,
                                nameBackground: background
                            },
                        ],
                        data:[]
                    });
                }
            }
        }
    }

    return tree;
}

export default function customerSystemReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SYSTEMS_CHECK_LIST_MOBILE:
            return { ...state, systemsCheckListMobile: state.systemsCheckListMobile };
        case GET_SYSTEMS_CHECK_LIST_TABLET:
            return { ...state, systemsCheckListTablet: state.systemsCheckListTablet };
        case GET_SYSTEMS_BY_ORDER_ID:
            const firstSystemName = action.payload[0]?.systems[0]?.systems[0];
            if (!firstSystemName) {
                return {
                    ...state,
                }
            }
            return { ...state, systemsAllData: action.payload, originSystemData: action.payload};
        case GET_SYSTEMS_FOR_ACCORDION:
            return { ...state, systemsForAccordion: state.systemsForAccordion };
        case SET_SYSTEMS_FOR_ACCORDION:
            const systemsForAccordion = getSystemForAccordion(state, action);
           

            return { 
                ...state, 
                systemsForAccordion, 
                originSystemData: action.payload,
                systemsAllData: action.payload
            }
        case ADD_SYSTEM:
            const newAccordionTree2 = newPushNewSystemToSystemsForAccordion(state.originSystemData, action.payload)
            const accordionData = getSystemForAccordion(state, {
                payload: newAccordionTree2,
            })
         
            return { ...state, systemsAllData: newAccordionTree2, originSystemData: newAccordionTree2, systemsForAccordion:  accordionData };

        case SELECTED_SYSTEM_BY_SYSTEM_ID:
            const selectSystemsByLayer = state.originSystemData[action.payload?.layer - 1];
            const selectedSystemBySystemId = getSelectedSystemBySystemId(selectSystemsByLayer?.systems, action.payload?.systemId);

            return { ...state, singleSystem: selectedSystemBySystemId }


        case EDIT_SYSTEM:
            const systemsAfterEditSelectedSystem = state.originSystemData.map((systemsByLayer) => {
                if (systemsByLayer.layer === action.payload.layer) {
                    const allSystemsFromSelectedLayerAfterEdit = editSystemFromSelectedLayerSystems(systemsByLayer, action.payload);
                    return allSystemsFromSelectedLayerAfterEdit;
                } else {
                    return systemsByLayer;
                }
            })
            return { ...state, originSystemData: systemsAfterEditSelectedSystem };


        case DELETE_SYSTEM:
            const systemsAfterDeleteSelectedSystem = state.originSystemData.map((systemsByLayer) => {
                if (systemsByLayer.layer === action.payload.layer){
                    const remainingSystemsFromSelectedLayerAfterDelete = deleteSystemFromSelectedLayerSystems(systemsByLayer, action.payload.systemId, action.payload.parentSystemId);
                    return remainingSystemsFromSelectedLayerAfterDelete;
                }else{
                    return systemsByLayer;
                }
            })
            return { 
                ...state, 
                originSystemData: systemsAfterDeleteSelectedSystem 
            };

            
        case GET_CHECK_LIST_BY_SYSTEM_ID:

            let filteredSystemByLayer = null;
            const systemsByLayer = state?.originSystemData[action.payload?.layer - 1]
            for (let _system of systemsByLayer.systems){
                for (let __system of _system.systems){
                    if (action.payload.systemId === __system.system_id) {
                        // match checklist
                        if(__system.check_list !== null){
                            if(action.payload.parentSystemId){
                                filteredSystemByLayer = {...__system.check_list, data: __system.check_list?.data.filter((item) => ((item.selected_process === action.payload.processId) && item.selected_upper_layer_system === action.payload.parentSystemId) )}
                            }else{
                                filteredSystemByLayer = {...__system.check_list, data: __system.check_list?.data.filter((item) => ((item.selected_process === action.payload.processId)) )}
                            }
                        } else{
                            filteredSystemByLayer = __system.check_list
                        }
                    } 
                }
            }
            

            const checkListRows = filteredSystemByLayer && filteredSystemByLayer.data?.map((row) => {
                return row;

            })

            return { ...state, systemsCheckListTablet: { ...state.systemsCheckListTablet, rows: checkListRows, actualSystemId: action.payload.systemId } }

        case MOVE_SYSTEM_ROW:
        // const reordredAccordionData = reorderSystems({ oldIndex: action.payload.index, newIndex: action.payload.index, direction: action.payload.direction, layer: action.payload.layer }, state.systemsForAccordion);
        // return { ...state, systemsForAccordion: reordredAccordionData };
        case ADD_CHECK_LIST:
            let newCheckList = [
                {
                    typeOfInput: null,
                    value: "תקין"
                },
                {
                    options: [],
                    typeOfInput: -11,
                    value: "תקין"
                },
                {
                    typeOfInput: null,
                    value: "",
                },
                {
                    typeOfInput: null,
                    value: false,
                },
                {
                    placeholder: "יש לציין זרם מדוד",
                    typeOfInput: "null",
                    value: ""
                },
                {
                    typeOfInput: "null",
                    value: []
                }
            ]
            let newRows = [];
            if (state.systemsCheckListTablet.rows) {
                newRows = [...state.systemsCheckListTablet.rows, newCheckList]
            } else {
                newRows = [newCheckList];
            }
            return { ...state, systemsCheckListTablet: { ...state.systemsCheckListTablet, rows: newRows } }

        case SET_SYSTEM_FOR_SYSTEMS_OPENING_PATH: 
            const newOpenedSystemPath = {
                systemName: action.payload.system_name || action.payload.systemName,
                layer: action.payload.layer,
                isActive: action.payload || true
            }
            return { ...state, systemsOpeningPath: [...state.systemsOpeningPath, newOpenedSystemPath] }

        case REMOVE_SYSTEM_FROM_SYSTEMS_OPENING_PATH:
           
            for (let systemIdx = state.systemsOpeningPath.length-1; systemIdx >= 0; systemIdx--){
                const _pathSystem = state.systemsOpeningPath[systemIdx];
                const currentSystemLayer = action.payload;

                if (_pathSystem.layer >= currentSystemLayer){
                    state.systemsOpeningPath.pop();
                }
            }
           let resetPath = state.systemsOpeningPath;
            return { ...state, systemsOpeningPath: resetPath }

        case ADD_SYSTEM_MEASUREMENT:  
            return {...state, systemMeasurement: action.payload}   
        

        case SHOW_SYSTEMS_POP_UP: 
            return { ...state, isShowSystemsPopup: action.payload }
        case UPDATE_SYSTEM_CHECKLIST: 
            const updatedRows = state.systemsCheckListTablet.rows.map((row, rowIndex) => {
                if (rowIndex === action.payload.rowIdx) {
                    return {
                        ...row,
                        values: row.values.map((value, colIndex) => 
                            colIndex === action.payload.colIdx
                                ? { ...value, value: action.payload.value }
                                : value
                        )
                    };
                }
                return row;
            });
        
            return {
                ...state,
                systemsCheckListTablet: {
                    ...state.systemsCheckListTablet,
                    rows: updatedRows
                }
            };
    
        default:
            return state;

    }
}