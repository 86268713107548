import { Typography } from '@material-ui/core'
import React, { Fragment, useEffect } from 'react'
import "./SystemsList.scss";
import AccordionMain from '../../../AccordionRow/AccordionMain';
import { accordionTree1 } from '../../../AccordionRow/accordionRowData';

import { TABLET_VIEW_TRANSLATION } from '../../../../../constants/translations/customer/tablet/tabletView';
import { useDispatch, useSelector } from 'react-redux';
import EmptyList from '../EmptyList/EmptyList';
import TabEmptyLanding from '../../../MobileViews/TabEmptyLanding/TabEmptyLanding';
import { systemActionsCreator } from '../../../../../actions/customer/systemActions';

function getSystemPath(pathArray) {
  return pathArray.map((openedSystem, index) => {
    return <span key={openedSystem.systemName + index}>
      {
        (pathArray.length > 1 &&( index >= pathArray.length - 1)) ? <span> &#62;</span> : null
      }
      <span className={index === 0 ? 'activeSystem': null}>{openedSystem.systemName}</span>
    </span>
  })
}

export default function SystemsList(props) {
  const { popupClickHandlers, onClickHandler, process, setSelectedParentSystem,  selectedParentSystem } = props;
  const orderId = process && (process.order_id || process.orderId);

  const dispatch = useDispatch()
  const accordionDataTree = useSelector((state) => state.customerSystems.systemsForAccordion);
  const systemsOpeningPath = useSelector((state) => state.customerSystems.systemsOpeningPath);

  useEffect(() => {
    // '646318afdf8e59467ce60344'
    // 63ac4b587fd3fa5e2c7b1e37
    dispatch(systemActionsCreator.getSystemsByOrderId(orderId));
    // dispatch(systemActionsCreator.getSystemsForAccordion());
  }, [orderId])

  const view = 'tablet'
  return (
    <>
      <h4 className='system-tab-main__title'>{TABLET_VIEW_TRANSLATION.SYSTEMS_LIST}</h4>
      <Typography variant="caption" className='system-tab-main__text'>{getSystemPath(systemsOpeningPath)}</Typography>
      <div className='accordion__list'>
        {
          accordionDataTree.length ?
          accordionDataTree.map((accordionData, index) => {
            return <Fragment key={index}>
              <AccordionMain
                data={accordionData.data}
                systemId={accordionData.id}
                view={view}
                onClickHandler={onClickHandler}
                popupClickHandlers={popupClickHandlers}
                rowIndex={index}
                setSelectedParentSystem={setSelectedParentSystem}
                selectedParentSystem={selectedParentSystem}
                process={process}
              />
            </Fragment>
          })
            : view !== 'mobile' ? <EmptyList name={'system'} /> : <TabEmptyLanding title={'system'} />
        }
      </div>
    </>
  )
}
{/* accordionDataTree.length ?  */ }
{/* : view !== 'mobile' ? <EmptyList name={'system'} /> : <TabEmptyLanding title={'system'} /> */ }