export const mobileTasksList = {
    view: 'tablet',
    popUpData: [
        {
            label: "Edit Task",
            icon: 'edit'
        },
        {
            label: "Delete Task",
            icon: 'delete'
        },
        {
            label: "Move Up",
            icon: 'up'
        },
    ],
    columns: [
        {
            field: 'fileName',
            headerName: 'Task Name',
        },
        {
            field: 'resourceName',
            headerName: 'Resource Name',
        },
        {
            field: 'dueDate',
            headerName: 'Due Date',
        },
        {
            field: 'status',
            headerName: 'Status',
        },
        {
            field: 'Actions',
            headerName: 'Actions',
        },
    ],
    rows: [
        { id: 1, fileName: 'Forem ipsum dolor sit', resourceName: 'Jakob Baptista', dueDate: '15/06/2023', status: 'Not Done', },
        { id: 2, fileName: 'Forem ipsum dolor sit', resourceName: 'Jakob Baptista', dueDate: '15/06/2023', status: 'Not Done', },
        { id: 3, fileName: 'Forem ipsum dolor sit', resourceName: 'Jakob Baptista', dueDate: '15/06/2023', status: 'Not Done', },
        { id: 3, fileName: 'Forem ipsum dolor sit', resourceName: 'Jakob Baptista', dueDate: '15/06/2023', status: 'Not Done', },
        { id: 4, fileName: 'Forem ipsum dolor sit', resourceName: 'Jakob Baptista', dueDate: '15/06/2023', status: 'Not Done', },
        { id: 5, fileName: 'Forem ipsum dolor sit', resourceName: 'Jakob Baptista', dueDate: '15/06/2023', status: 'Not Done', },
        { id: 6, fileName: 'Forem ipsum dolor sit', resourceName: 'Jakob Baptista', dueDate: '15/06/2023', status: 'Not Done', },

    ],
}

export const tabletTasksList = {
    view: 'tablet',
    popUpData: [
        {
            label: "Edit Task",
            icon: 'edit'
        },
        {
            label: "Delete Task",
            icon: 'delete'
        },
        {
            label: "Move Up",
            icon: 'up'
        },
    ],
    columns: [
        {
            field: 'fileName',
            headerName: 'Task Name',
        },
        {
            field: 'resourceName',
            headerName: 'Resource Name',
        },
        {
            field: 'dueDate',
            headerName: 'Due Date',
        },
        {
            field: 'status',
            headerName: 'Status',
        },
        {
            field: 'Actions',
            headerName: 'Actions',
        },
    ],
    rows: [
        { id: 1, fileName: 'Forem ipsum dolor sit', resourceName: { placeholder: 'Jakob Baptista', selectionOptions: ["png", "jpeg", "pdf", "text"] }, dueDate: '15/06/2023', status: { placeholder: 'Not Done', value: 'Not Done', selectionOptions: ["png", "jpeg", "pdf", "text"] } },
        { id: 1, fileName: 'Forem ipsum dolor sit', resourceName: { placeholder: 'Jakob Baptista', selectionOptions: ["png", "jpeg", "pdf", "text"] }, dueDate: '15/06/2023', status: { placeholder: 'Not Done', value: 'Not Done', selectionOptions: ["png", "jpeg", "pdf", "text"] } },
        { id: 1, fileName: 'Forem ipsum dolor sit', resourceName: { placeholder: 'Jakob Baptista', selectionOptions: ["png", "jpeg", "pdf", "text"] }, dueDate: '15/06/2023', status: { placeholder: 'Not Done', value: 'Not Done', selectionOptions: ["png", "jpeg", "pdf", "text"] } },
        { id: 1, fileName: 'Forem ipsum dolor sit', resourceName: { placeholder: 'Jakob Baptista', selectionOptions: ["png", "jpeg", "pdf", "text"] }, dueDate: '15/06/2023', status: { placeholder: 'Not Done', value: 'Not Done', selectionOptions: ["png", "jpeg", "pdf", "text"] } },
        { id: 1, fileName: 'Forem ipsum dolor sit', resourceName: { placeholder: 'Jakob Baptista', selectionOptions: ["png", "jpeg", "pdf", "text"] }, dueDate: '15/06/2023', status: { placeholder: 'Not Done', value: 'Not Done', selectionOptions: ["png", "jpeg", "pdf", "text"] } },
        { id: 1, fileName: 'Forem ipsum dolor sit', resourceName: { placeholder: 'Jakob Baptista', selectionOptions: ["png", "jpeg", "pdf", "text"] }, dueDate: '15/06/2023', status: { placeholder: 'Not Done', value: 'Not Done', selectionOptions: ["png", "jpeg", "pdf", "text"] } },
        { id: 1, fileName: 'Forem ipsum dolor sit', resourceName: { placeholder: 'Jakob Baptista', selectionOptions: ["png", "jpeg", "pdf", "text"] }, dueDate: '15/06/2023', status: { placeholder: 'Not Done', value: 'Not Done', selectionOptions: ["png", "jpeg", "pdf", "text"] } },
        { id: 1, fileName: 'Forem ipsum dolor sit', resourceName: { placeholder: 'Jakob Baptista', selectionOptions: ["png", "jpeg", "pdf", "text"] }, dueDate: '15/06/2023', status: { placeholder: 'Not Done', value: 'Not Done', selectionOptions: ["png", "jpeg", "pdf", "text"] } },
        { id: 1, fileName: 'Forem ipsum dolor sit', resourceName: { placeholder: 'Jakob Baptista', selectionOptions: ["png", "jpeg", "pdf", "text"] }, dueDate: '15/06/2023', status: { placeholder: 'Not Done', value: 'Not Done', selectionOptions: ["png", "jpeg", "pdf", "text"] } },
        { id: 1, fileName: 'Forem ipsum dolor sit', resourceName: { placeholder: 'Jakob Baptista', selectionOptions: ["png", "jpeg", "pdf", "text"] }, dueDate: '15/06/2023', status: { placeholder: 'Not Done', value: 'Not Done', selectionOptions: ["png", "jpeg", "pdf", "text"] } },
        { id: 1, fileName: 'Forem ipsum dolor sit', resourceName: { placeholder: 'Jakob Baptista', selectionOptions: ["png", "jpeg", "pdf", "text"] }, dueDate: '15/06/2023', status: { placeholder: 'Not Done', value: 'Not Done', selectionOptions: ["png", "jpeg", "pdf", "text"] } },
        { id: 1, fileName: 'Forem ipsum dolor sit', resourceName: { placeholder: 'Jakob Baptista', selectionOptions: ["png", "jpeg", "pdf", "text"] }, dueDate: '15/06/2023', status: { placeholder: 'Not Done', value: 'Not Done', selectionOptions: ["png", "jpeg", "pdf", "text"] } },
        // { id: 1, fileName: 'Forem ipsum dolor sit', resourceName: { placeholder: 'Jakob Baptista', selectionOptions: ["png", "jpeg", "pdf", "text"] }, dueDate: '15/06/2023', status: { placeholder: 'Not Done', value: 'Not Done', selectionOptions: ["png", "jpeg", "pdf", "text"] } },
        { id: 1, fileName: 'Forem ipsum dolor sit', resourceName: { placeholder: 'Jakob Baptista', selectionOptions: ["png", "jpeg", "pdf", "text"] }, dueDate: '15/06/2023', status: { placeholder: 'Not Done', value: 'Not Done', selectionOptions: ["png", "jpeg", "pdf", "text"] } },


    ],
}