import { POP_UPS_TRANSLATION } from "../../../../constants/translations/customer/PopUps/PopUps"
import { TABLET_VIEW_TRANSLATION } from "../../../../constants/translations/customer/tablet/tabletView"
import { CANCEL } from "../../../../constants/translations/review-popup"

export const systemTabBtns = {
    system: {
        name: TABLET_VIEW_TRANSLATION.SYSTEM,
        width: '30%',
        height: '70px',
        color: '#fff',
        backgroundColor: "#186315",
        fontSize: '24px',
        fontWeight: 800,
        icon: true,
        iconContainer: true,
        iconContainerWidth: '32px',
        border: false,
        borderRadius: '90px',
        buttonName: 'AddIcon'
    },
    startTimeCount: {
        name: `${TABLET_VIEW_TRANSLATION.STARTED_AT}`, //'Started at 9 am',
        width: '40%',
        height: '70px',
        color: '#186315',
        backgroundColor: "#fff",
        fontSize: '24px',
        fontWeight: 800,
        icon: true,
        iconContainer: true,
        iconContainerWidth: '32px',
        border: true,
        borderRadius: '90px',
        buttonName: 'play'
    },
    save: {
        name: TABLET_VIEW_TRANSLATION.FILE,
        width: '30%',
        height: '2rem',
        color: '#fff',
        backgroundColor: "#0091FF",
        fontSize: '1.3rem',
        fontWeight: 800,
        icon: false,
        iconContainer: false,
        border: false,
        borderRadius: '90px',
    },
    cancel: {
        name: CANCEL,
        width: '30%',
        height: '70px',
        color: '#0091FF',
        backgroundColor: "#fff",
        fontSize: '24px',
        fontWeight: 800,
        icon: false,
        iconContainer: false,
        border: true,
        borderRadius: '90px',
    }
}

export const systemTabBtnsTablet = {
    startTimeCount: {
        ...systemTabBtns.startTimeCount,
        height: '2.5rem',
        fontSize: '16px',
        borderRadius: '20px',
        iconContainerWidht: '26px',
        width: '50%',
    },
    system: {
        ...systemTabBtns.system,
        height: '2.5rem',
        fontSize: '16px',
        borderRadius: '20px',
        iconContainerWidht: '26px',
        width: '40%',
    },
    save: {
        ...systemTabBtns.save,
        with: '35%',
        height: '2.5rem',
        fontSize: '16px',
        borderRadius: '20px',
    },
    cancel: {
        ...systemTabBtns.cancel,
        with: '35%',
        height: '2.5rem',
        fontSize: '16px',
        borderRadius: '20px',
    },
}

export const deleteInfoPopUp = {
    title: POP_UPS_TRANSLATION.DELETE_SYSTEM,
    subtitle: POP_UPS_TRANSLATION.DELETE_SYSTEM_CONFIRMATION,
    info: [
        { label: POP_UPS_TRANSLATION.SYSTEM_NAME, value: POP_UPS_TRANSLATION.SYSTEM_NAME_VALUE },
        { label: POP_UPS_TRANSLATION.FLOOR, value: '6' },
        { label: POP_UPS_TRANSLATION.STATUS, value: '6' },
    ]
}
