import { MOBILE_LANDING_PAGE } from "../../../../constants/translations/customer/mobile/mobileView"
import { TABLET_VIEW_TRANSLATION } from "../../../../constants/translations/customer/tablet/tabletView"

export const dataInformationInput = {
    resourceName: {
        label: MOBILE_LANDING_PAGE.RESOURCE_NAME, //'Resource name'
        placeholder: 'Dorem ipsum, Dorem ipsum, Dorem ipsum',
        value: 'Dorem ipsum, Dorem ipsum, Dorem ipsum',
        type: 'select',
        inputFor: "dataInforamtionInput",
        name: 'resourceName',
        //width: '10%'
    },
    reported: {
        label: TABLET_VIEW_TRANSLATION.REPORTED, // 'Reported',
        placeholder: '0',
        value: '0',
        type: 'number',
        inputFor: "dataInforamtionInput",
        name: 'reported',
        // width: '10%'
    },
    ordered: {
        label: TABLET_VIEW_TRANSLATION.ORDERED, //'Ordered',
        placeholder: '0',
        value: '0',
        type: 'number',
        inputFor: "dataInforamtionInput",
        name: 'ordered',
        // width: '12%'
    }
}

export const dataInformationBtn = {
    reportOfCompletion: {
        name: MOBILE_LANDING_PAGE.REPORT_OF_COMPLETION, //'Report of termination'
        width: '100%',
        height: '70px',
        color: '#fff',
        backgroundColor: "#0091FF",
        fontSize: '24px',
        fontWeight: 800,
        icon: false,
        iconContainer: false,
        border: false,
        isBoxShadow: true,
        buttonName: 'AddIcon',
        padding: '5px 10px',
        borderRadius: '90px',
    },
    submitForCompletion: {
        name: MOBILE_LANDING_PAGE.SUBMIT_FOR_COMPLETION,//'Submit for completion',
        width: '100%',
        height: '70px',
        color: '#455768',
        backgroundColor: "#fff",
        fontSize: '24px',
        fontWeight: 800,
        icon: false,
        iconContainer: false,
        border: true,
        isBoxShadow: true,
        buttonName: 'AddIcon',
        padding: '5px 10px',
        borderRadius: '90px',
    },
    isParallelProcess: {
        name: MOBILE_LANDING_PAGE.PARALLEL,
        width: '100%',
        height: '70px',
        color: '#ffffff', //'#455768',
        backgroundColor: "gray",
        fontSize: '24px',
        fontWeight: 800,
        icon: false,
        iconContainer: false,
        border: true,
        isBoxShadow: true,
        buttonName: 'AddIcon',
        padding: '5px 10px',
        borderRadius: '90px',
    }
}
export const dataInformationBtnMobile = {
    reportOfCompletion: {
        ...dataInformationBtn.reportOfCompletion,
        height: '36px',
        fontSize: '10px',
        // padding: '14px 16px',
        borderRadius: '20px',
    },
    submitForCompletion: {
        ...dataInformationBtn.submitForCompletion,
        height: '36px',
        fontSize: '10px',
        // padding: '14px 16px',
        borderRadius: '20px',
    },
    isParallelProcess: {
        ...dataInformationBtn.isParallelProcess,
        height: '36px',
        fontSize: '10px',
        // padding: '14px 16px',
        borderRadius: '20px',
    }
}
export const dataInformationBtnDesktop = {
    reportOfCompletion: {
        ...dataInformationBtn.reportOfCompletion,
        height: '2rem',
        fontSize: '14px',
        borderRadius: '20px',
    },
    submitForCompletion: {
        ...dataInformationBtn.submitForCompletion,
        height: '2rem',
        fontSize: '14px',
        borderRadius: '20px',
    },
    isParallelProcess: {
        ...dataInformationBtn.isParallelProcess,
        height: '2rem',
        fontSize: '14px',
        borderRadius: '20px',
        width: '90%'
    }
}
