import { ADD_CHECK_LIST, ADD_SYSTEM, ADD_SYSTEM_MEASUREMENT, DELETE_SYSTEM, EDIT_SYSTEM, GET_CHECK_LIST_BY_SYSTEM_ID, GET_SYSTEMS_BY_ORDER_ID, GET_SYSTEMS_CHECK_LIST_MOBILE, GET_SYSTEMS_CHECK_LIST_TABLET, GET_SYSTEMS_FOR_ACCORDION, MOVE_SYSTEM_ROW, REMOVE_SYSTEM_FROM_SYSTEMS_OPENING_PATH, SELECTED_SYSTEM_BY_SYSTEM_ID, SET_SYSTEMS_FOR_ACCORDION, SET_SYSTEM_FOR_SYSTEMS_OPENING_PATH, SHOW_SYSTEMS_POP_UP, UPDATE_SYSTEM_CHECKLIST } from "../../constants/customer/systemConstants";
import { getOrderSystemsList } from "../../functions/api/orders";
import * as types from "../types";



function transformCustomerDataToAccordionTreeResponse(systems) {

    const result = []
    const systemsByLayer = {}
    for (const system of systems) {
        const {
            layer,
            // order,
            parent_system_id,
        } = system;



        if (layer in systemsByLayer) {
            if (parent_system_id in systemsByLayer[layer]) {
                systemsByLayer[layer][parent_system_id].push(system)
            } else {
                systemsByLayer[layer][parent_system_id] = [system];
            }
        } else {
            systemsByLayer[layer] = {
                [parent_system_id]: [system]
            };
        }
    }

    for (const layer in systemsByLayer) {
        const systemLayerObj = {
            layer: parseInt(layer),
            systems: []
        }
        for (const parent_system_id in systemsByLayer[layer]) {
            const obj = {
                parent_system_id: parent_system_id == "null" ? null : parent_system_id,
                systems: []
            }
            for (const system of systemsByLayer[layer][parent_system_id]) {
                obj.systems.push(system);
            }
            systemLayerObj.systems.push(obj);
        }

        result.push(systemLayerObj);

        
    }
    return result;
}

const getCustomersDataRequest = () => {
    return {
        type: types.GET_CUSTOMERS_INFO_REQUEST
    }
}
const getCustomersDataSuccess = (data) => {
    return {
        type: types.GET_CUSTOMERS_INFO_SUCCESS,
        payload: data
    }
}

export const updateSystemChecklist = (data)  => {
    return async (dispatch) =>{
        dispatch({ type: UPDATE_SYSTEM_CHECKLIST, payload: data })
    }
    
}

const getCustomersDataError = (err) => {
    return {
        type: types.GET_CUSTOMERS_INFO_ERROR,
        error: err
    }
}

export const systemActionsCreator = {
    getSystemsCheckListMobile: ()=>({type: GET_SYSTEMS_CHECK_LIST_MOBILE}),
    getSystemsCheckListTablet: () => ({ type: GET_SYSTEMS_CHECK_LIST_TABLET}),
    getSystemsByOrderId: (orderId) => {
        return async (dispatch) =>{
           const response = await getOrderSystemsList(orderId);

            dispatch({ type: GET_SYSTEMS_BY_ORDER_ID, payload: response.result })
            dispatch({ type: SET_SYSTEMS_FOR_ACCORDION, payload: response.result })
        }
    },
    getSystemsByCustomer: (customer) => {

        return async (dispatch) =>{
            try {
                const response = transformCustomerDataToAccordionTreeResponse(customer.systems);
 
                dispatch({ type: GET_SYSTEMS_BY_ORDER_ID, payload: response })
                dispatch({ type: SET_SYSTEMS_FOR_ACCORDION, payload: response })
            } catch (err) {
                console.log("Error", err);
            }
           
         }
    },

    getSystemsForAccordion: () =>{ 
        return({type: GET_SYSTEMS_FOR_ACCORDION})},
    addSystem: (system) => ({
        type: ADD_SYSTEM, payload: system
    }),
    selectedSystemBySystemId: (selectedBy) => {
        return ({ type: SELECTED_SYSTEM_BY_SYSTEM_ID, payload: selectedBy })
    },
    editSystem : (system) => ({
        type: EDIT_SYSTEM, payload: system
    }),
    deleteSystem: (deleteBy) => {
       return ({
           type: DELETE_SYSTEM, payload: deleteBy
    })},
    getCheckListBySystemId: (systemId) => {
        return({
        type: GET_CHECK_LIST_BY_SYSTEM_ID, payload: systemId
    })},
    moveSystemRow: (currentRowInfo) => ({ type: MOVE_SYSTEM_ROW, payload: currentRowInfo }),
    addCheckList: () => ({type: ADD_CHECK_LIST}),
    setSystemForAccordion: () => {
        return ({
            type: SET_SYSTEMS_FOR_ACCORDION,
        })
    },
    setSystemsOpeningPath: (system) => ({ type: SET_SYSTEM_FOR_SYSTEMS_OPENING_PATH, payload: system }),
    removeSystemFromOpeningPath: (system) => ({ type: REMOVE_SYSTEM_FROM_SYSTEMS_OPENING_PATH, payload: system }),
    
    addSystemMeasurement: (measurement) => ({ type: ADD_SYSTEM_MEASUREMENT, payload: measurement }),


    showSystemsPopup: (boolean) => ({ type: SHOW_SYSTEMS_POP_UP, payload: boolean }),

   

}