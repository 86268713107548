/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import MobileHeader from '../../MobileViews/Header/MobileHeader';
import "./TabletViewMainScreen.scss";
import DataInformation from '../DataInformation/DataInformation';
import DetailsNavigation from '../../CustomerDetails/DetailsNavigation/DetailsNavigation';
import FileTab from '../FileTab/FileTab';
import TaskTab from '../TaskTab/TaskTab';
import SystemsTab from '../SystemsTab/SystemsTab';
import DetailsTab from '../DetailsTab/DetailsTab';
import { FILES, SYSTEMS, TASKS } from '../../../../constants/translations/review-popup';
import { TABLET_VIEW_TRANSLATION } from '../../../../constants/translations/customer/tablet/tabletView';
import { generalGetRequest } from '../../../../functions/api/general';
import SystemTabDesktop from '../../CustomerDetails/SystemTab/SystemTab';
import { useDispatch, useSelector } from 'react-redux';
import AddOrderPopUp from '../../../AddOrder/AddOrderPopUp/AddOrderPopUp';
import { showMainPopup } from '../../../../actions/mainPopup_actions';
import OrderFormNewPopup from '../../../Header/Parts/ReservationPopup/orderFormNewPopup';
import ErrorPopup from '../../../NavBar/ErrorPopup/ErrorPopup';
import { setShowSidebarCalendar } from '../../../../actions/general_actions';
import {FULL_SCREEN_ALLOWED} from '../../../../tools/keys/variables'
// import {
//     showMainPopup,
// } from "../../../../actions/mainPopup_actions";

let navigationData = {
    navigationFor: 'normal',
    navigationTab: [
        { label: SYSTEMS, value: 12 },
        { label: FILES, value: 12 },
        { label: TASKS, value: 12 },
        { label: TABLET_VIEW_TRANSLATION.DETAILS },
    ]
}

export default function TabletViewMainScreen(props) {
    const { orderId, process, view, updateErrorPopup: updateErrorPopupProcess, selectedProcess: selected_process } = props;
    const [showPopUp, setShowPopUp] = useState(false);
    const [selectedProcess, setSelectedProcess] = useState({});
    const [lockPopup, setLockPopup] = useState(false);
    const [navLength, setNavLength] = useState({
        files: 0,
        tasks: 0,
        systems: 0,
    })
    const [showAddOrderPopUp, setShowAddOrderPopUp] = useState(false);
    const [inputValue, setInputValue] = useState(
        { 
            ordered: process?.quantity, 
            orderedError: false, 
            reported: process?.finished, 
            reportedError: false, 
            resourceName: process?.resource, 
            resourceNameError: false, 
        });
    const [backlog, setBacklog] = useState(process && process.backlog);
    const [isParallelProcess, setIsParallelProcess] = useState(process?.should_occur_parallel ? process?.should_occur_parallel : false);
    const [activeFullScreenMode, setActiveFullScreenMode] = useState(false)


    const [showErrorPopup, setShowErrorPopup] = useState({
        show_error_popup: false,
        error_text: null,
    });


    const systems = useSelector((state) => state.customerSystems);
    const tasks = useSelector((state) => state.tasks.tasksTablet);
    const files = useSelector((state) => state.files.filesDesktop);
    const mainPopupSlice = useSelector((state) => state.mainPopup);
    const isCheckListLength = systems.systemsCheckListTablet?.rows && systems.systemsCheckListTablet?.rows.length>0 ? true : false

    // const [selectedTab, setSelectedTab] = useState(view === 'tablet' && isCheckListLength ? 0 : 3);
    const [selectedTab, setSelectedTab] = useState(0);

    const dispatch = useDispatch();

    function changeSelectionTab(newValue) {
        setSelectedTab(newValue);
    }

    function handleShowPopUp() {
        setShowPopUp(!showPopUp)
    }

    function closePopup(){
        props.closePopup();
        dispatch(setShowSidebarCalendar(true));
    }
    function handleShowLockPopUP() {
        setLockPopup(!lockPopup)
    }
    const getOrderDataFromProcess = async (orderId) => {
        let res = await generalGetRequest(`/system/order-process/get-last-order-process?order_id=${orderId}`)
        setSelectedProcess({
            process: res.result,
        })

    }

    // useEffect(() => {
    //     getOrderDataFromProcess(orderId)

    // }, [selectedTab])

    const enterFullscreen = () => {
        if(!FULL_SCREEN_ALLOWED) {return}
        const element = document.documentElement;
       
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
        setActiveFullScreenMode(true)
    };

    const exitFullscreen = () => {
        console.log("activeFullScreenMode", )
        if(!activeFullScreenMode){
            return;
        }
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;

            if(view === 'tablet'){
               enterFullscreen && enterFullscreen();
            }
            // Check if the screen width is between 1000px and 1500px
            if (screenWidth >= 1000 && screenWidth <= 2000 && view === 'tablet') {
                if (!document.fullscreenElement) {
                    enterFullscreen();
                }
            } else {
                if (document.fullscreenElement) {
                    exitFullscreen();
                }
            }
        };

      
       
        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [view]);


    useEffect(() => {
        if (selectedTab === 0) {
            setNavLength({ ...navLength, systems: systems.systemsForAccordion?.length })
        } else if (selectedTab === 1) {
            setNavLength({ ...navLength, files: files.rows?.length })
        } else if (selectedTab === 2) {
            setNavLength({ ...navLength, tasks: tasks?.rows.length })
        }
    }, [selectedTab, systems, files, tasks])

    navigationData = {
        ...navigationData, navigationTab: [
            { label: SYSTEMS, value: navLength.systems },
            { label: FILES, value: navLength.files },
            { label: TASKS, value: navLength.tasks },
            { label: TABLET_VIEW_TRANSLATION.DETAILS },
        ]
    }

    function handleShowAddOrderPopUp() {
        setShowAddOrderPopUp(!showAddOrderPopUp)
        // dispatch(showMainPopup(!mainPopupSlice.show_popup));
    }

    const user = {
        user: {
            factory_id: process?.factory_id
        } 
    }

    const updateErrorPopup = (boolean, error_text, isUpdateCall = false) => {
        setShowErrorPopup({
            show_error_popup: boolean,
            error_text: error_text,
        });
    }

    return (
        <div className={`${view === 'desktop' ? 'tabletViewMain--desktop' : 'tabletViewMain'}`}>
            <MobileHeader
                headerName="File"
                processName={process && process.process_name || 'Installation'}
                customerName={process && process.client_name || 'Zain bator'}
                orderNumber={process && process.order_number || ' S02100004B'}
                process={process && process}
                view={view === 'tablet' ? 'desktop' : view}
                closePopup={closePopup}
                exitFullscreen={exitFullscreen}
                lockPopup={lockPopup}
                handleShowLockPopUP={handleShowLockPopUP}
                handleShowAddOrderPopUp = {handleShowAddOrderPopUp}
            />

            <section className='dataContainer'>
                <DataInformation
                    view={view === 'tablet' ? 'desktop' : view}
                    inputValue={inputValue}
                    backlog={backlog}
                    setBacklog={setBacklog}
                    // resource={inputValue && inputValue.resourceName}
                    // order={inputValue && inputValue.ordered}
                    // city={process && process.city}
                    process={process && process}
                    selected_process={selected_process}
                    // reported={inputValue && inputValue.reported}
                    setInputValue={setInputValue}
                    isParallelProcess={isParallelProcess}
                    setIsParallelProcess={setIsParallelProcess}
                />
                <div className='navigation__container'>
                    <DetailsNavigation
                        changeTab={changeSelectionTab}
                        data={navigationData}
                        tabNumber={selectedTab}
                        view={view === 'tablet' ? 'desktop' : view}
                    />
                </div>
                <div style={{ marginTop: '10px' }}>
                    {
                        selectedTab === 1 ? <FileTab
                            showPopUp={showPopUp}
                            handleShowPopUp={handleShowPopUp}
                            closePopup={closePopup && closePopup}
                            process={process && process}
                            selected_process={selected_process}
                            view={view}
                            newID={'new'}
                        />
                            : selectedTab === 2 ? <TaskTab
                                showPopUp={showPopUp}
                                handleShowPopUp={handleShowPopUp}
                                closePopup={closePopup && closePopup}
                                orderProcessId={orderId && orderId}
                                orderProcess={selectedProcess.process}
                                selected_process={selected_process}
                                process={process && process}
                                view={view}
                            />
                                : selectedTab === 0 ? view === 'desktop' ?
                                    <SystemTabDesktop
                                        view={'desktop-popup'}
                                        process={process && process}
                                        closePopup={closePopup && closePopup}
                                    />
                                    : 
                                    <SystemsTab
                                        showPopUp={showPopUp}
                                        handleShowPopUp={handleShowPopUp}
                                        closePopup={closePopup && closePopup}
                                        selected_process={selected_process}
                                        process={process && process}
                                        view={'desktop'}
                                    />
                                    : selectedTab === 3 ? <DetailsTab
                                        showPopUp={showPopUp}
                                        handleShowPopUp={handleShowPopUp}
                                        closePopup={closePopup && closePopup}
                                        view={view}
                                        handleSubmit={() => {}}
                                        startTime={process && process.startTime}
                                        endTime={process && process.endTime}
                                        startDate={process && process.process_date}
                                        endDate={process && process.process_endDate}
                                        processDuration={process && process.process_duration}
                                        orderId={orderId && orderId}
                                        dataInfoInputValue={inputValue}
                                        backlog={backlog}
                                        isParallelProcess={isParallelProcess}
                                        process={process && process}
                                        updateErrorPopupProcess={updateErrorPopupProcess}
                                        exitFullscreen={exitFullscreen}
                                    />
                                        : null
                    }

                </div>
            </section>

            {
                lockPopup && <div className="custom__popup__locked-popup" style={{ position: 'absolute', top: '0', left: '0' }}>
                    <section className="custom__popup__update__submit-section">
                        <div>
                            שחרור נעילה הוא הליך בלתי הפיך, האם הינך בטוח שברצונך לשחרר
                            נעילה?
                        </div>
                        <div>
                            <button
                                onClick={() => {
                                    handleShowLockPopUP()
                                }}
                            >
                                ביטול
                            </button>
                            <button
                                onClick={() => {
                                    handleShowLockPopUP()
                                }}
                            >
                                אישור
                            </button>
                        </div>
                    </section>
                </div>
            }

            <div className={showAddOrderPopUp ? 'popUp__background' : ""}>
                {
                    showAddOrderPopUp ? 
                    <div className='addOderPopup__position'>
                            <OrderFormNewPopup
                                login = {user}
                                orderId={orderId}
                                closePopup={() => {
                                    handleShowAddOrderPopUp(false)
                                }}
                                view={view}
                                updateErrorPopup={updateErrorPopup}
                            />
                    </div>  : null
                } 
            </div>  
            {
                showErrorPopup.show_error_popup && (
                    <ErrorPopup
                        afterConfirmationError={showErrorPopup.error_text}
                        updatePopup={
                            (val) => {
                        setShowErrorPopup({ ...showErrorPopup, show_error_popup: val });
                       exitFullscreen();
                        }}
                    />
                )
            }
        </div>
    )
}
