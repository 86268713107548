import React, { useEffect, useState } from 'react';
import { Grid, Typography, makeStyles } from "@material-ui/core";
import "./CustomerInfoSidebar.scss";
import { useDispatch, useSelector } from 'react-redux';
import { custometDetailsActionCreater } from '../../../actions/customer/customerDetailsActions';
import { TABLET_VIEW_TRANSLATION } from '../../../constants/translations/customer/tablet/tabletView';
import moment from 'moment';

export const customerDetails = [
    {
        label: 'Customer number',
        value: 'PR40478954'
    },
    {
        label: ' Contact name',
        value: 'John Snow'
    },
    {
        label: 'Email',
        value: 'JohnSnow@mail.co.il'
    },
    {
        label: 'City',
        value: 'Tel Aviv Jaffa'
    },
    {
        label: 'Address',
        value: 'Rorem ipsum dolor sit amet'
    },
    {
        label: 'Year of occupancy',
        value: '06/17/2021'
    }
]
function convertKeyToName(key){
    let keyName = key.indexOf('_') > 0 ? key.split('_').join(' '): key;
    let firstChar = keyName.charAt(0).toUpperCase();
    let remainChars = keyName.slice(1);

    return firstChar + remainChars;
}

function getSelectedProperties(customer){
    const requiredProperties = [
        'customer_number',
        'name',
        'contact_name',
        'email',
        'phone',
        'city',
        'address',
        'year_of_occupancy',
        'terms_of_engagement',
    ];

    const selectedData = requiredProperties.reduce((acc, curr) => {
        acc[curr] = customer[curr];
        return acc;
    }, {});
    return selectedData;
}
function getCustomerDataInfoInToRequireFormate(customer){
    // get selected properties of customer object to show
  const selectedProperties = getSelectedProperties(customer);

  // formatted selected properties into array of objects
  const customerDetails = [];
    for (const key in selectedProperties) {
        let keyObject;
        if (key === 'year_of_occupancy'){
            keyObject = {
                label: convertKeyToName(key),
                field: key,
                value: moment(selectedProperties[key]).format('DD/MM/YYYY')
            }
        }else{
            keyObject = {
                label: convertKeyToName(key),
                field: key,
                value: selectedProperties[key]
            }
        } 
        customerDetails.push(keyObject);
    }
    return customerDetails;
}

export default function CustomerInfoSidebar(props) {
    const {customer} = props;
    const [customerDetailsState, seCustomerDetailsState] = useState([])

    const customerDetailsState1 =  Object.keys(customer).length && getCustomerDataInfoInToRequireFormate(customer)
    
    const dispatch = useDispatch();
    let customerDetails = useSelector((state) => state.customerDetails.customerDetails)
    
    if (customerDetailsState1.length){
        customerDetails = customerDetailsState1
    }

    return (
        <Grid container spacing={0} className='sidebar__main'>
            <div className='sidebar__main__heading sidebar__main__header'><span>{TABLET_VIEW_TRANSLATION.DETAILS}</span></div>
            <div className='sidebar__main__list__container' style={{width: '100%', height: 'calc(100vh - 350px)', overflowY: 'auto'}}>
                {
                    customerDetails?.length ? customerDetails.map((customer, index) => {
                        return (<Grid className='sidebar__main__item' key={index}>
                            <div>
                                <div className='sidebar__main__heading'>
                                    <span> {customer.label}</span>
                                </div>
                                <div className='sidebar__main__text'>
                                    <span>{customer.value}</span>
                                </div>
                            </div>

                        </Grid>)
                    }) : null
                }
            </div>
           
        </Grid>
    )
}
