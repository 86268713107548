export const constantDropdownReasonsOptions = [
    {name:'בקשת לקוח', value: 'Client Request'},
    {name:'בעיית ייצור', value: 'Production Problem'},
    {name:'בעיית תכנון', value: 'Planning Problem'}
]

export const PER_USER = '500'
export const NO_FINANCIALS = '100'
export const VIEW_ONLY = '200'
export const FULL_VIEW_ONLY = '900'
export const FULL_SCREEN_ALLOWED = process.env.REACT_APP_FULL_SCREEN_ALLOWED != 'false'
