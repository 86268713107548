export const TASKS_REPORT = `דו"ח משימות פתוחות`
export const MONTH_WORKLOAD_CHART = 'דו"ח עומסים חודשי'
export const WEEK_WORKLOAD_CHART = 'דוח עומס שבועי'
export const YEAR_WORKLOAD_CHART = 'דו"ח עומסים שנתי'
export const DEPARTMENT = 'מחלקה'
export const OVERLOADED = 'צפי עומסי יתר'
export const YEAR_FINANCIAL_CHART = `ניתוח פיננסי שנתי`
export const ORDER_PROCESSES_REPORT = 'דוח תהליך ההזמנה'
export const ORDER_TASKS_REPORT = {
  RESOURCE_NAME: `שם עובד`,
  CUSTOMER_NAME: `לקוח`,
  ORDER_NUMBER: `מספר הזמנה / קריאה`,
  PROCESS_NAME: `שלב`,
  OPEN_TASKS: `תיאור`,
  DUE_DATE: `תאריך יעד`,
  STATUS: `בוצע`,
  REMOVE: `לִמְחוֹק`,
  ORDER_NAME: "שם ההזמנה",
  DETAILS: "פרטים",
};

