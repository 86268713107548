import React, { useEffect, useState } from 'react';
import "./TaskTab.scss";
import Button from '../../Buttons/Button';
import { Grid } from '@material-ui/core';
import Table from '../../Table/Table2';
import AddTaskPopUp from '../PopUps/AddTask/AddTaskPopUp';
import DeletePopUp from '../PopUps/Delete/DeletePopUp';
import { useDispatch, useSelector } from 'react-redux';
import { taskActionsCreator } from '../../../../actions/customer/taskActions';
import { TASKS } from '../../../../constants/translations/review-popup';
import { POP_UPS_TRANSLATION } from '../../../../constants/translations/customer/PopUps/PopUps';
import { ORDER_TASKS_REPORT } from '../../../../constants/translations/order-tasks-report';
import { useTasks } from '../../../../hooks/useTasks';
import EmptyList from '../SystemsTab/EmptyList/EmptyList';
import { taskTabBtns, taskTabBtnsDesktop, taskTabBtnsTablet } from "./TaskTabStatic";


export default function TaskTab(props) {
    const { showPopUp, handleShowPopUp, closePopup, orderProcessId, orderProcess, process, view } = props
    const [tasksFromFormBody, setTasksFromFormBody] = useState([])
    const [tasks, setTasks] = useTasks(orderProcessId, tasksFromFormBody);
    const [currentPopup, setCurrentPopup] = useState('add');

    const dispatch = useDispatch();
    const taskTableData = useSelector((state) => state.tasks.tasksTablet)
    const deleteTaskDetails = useSelector((state) => state.tasks.singleTask);
    const [selectedId, setSelectedId] = useState(null);


    const newTask = (singleTask) => {
        setTasksFromFormBody([...tasksFromFormBody, singleTask])
    }

    useEffect(() => {
        dispatch(taskActionsCreator.setTasksToTasksListTablet(tasks))
    }, [tasks, process && process.customer_id])

    useEffect(() => {
        dispatch(taskActionsCreator.getTasksListTablet());
    }, [])

    const popupClickHandlers = {
        // 'Move Up': () => {
        //     setCurrentPopup('up');
        //     handleShowPopUp();
        // },
        'Edit Task': (taskId) => {
            setCurrentPopup('edit');
            dispatch(taskActionsCreator.selectedTaskByTaskId(taskId));
            handleShowPopUp();
        },
        'Delete Task': (taskId) => {
            setCurrentPopup('delete');
            handleShowPopUp();
            setSelectedId(taskId)
        },
    }

     useEffect(() => {
         dispatch(taskActionsCreator.selectedTaskByTaskId(selectedId))
     }, [selectedId])
     
    const deleteInfoPopUp = {
        title: POP_UPS_TRANSLATION.DELETE_TASK,
        subtitle: POP_UPS_TRANSLATION.DELETE_TASK_CONFIRMATION,
        info: [
            {
                label: POP_UPS_TRANSLATION.TASK_NAME, value: deleteTaskDetails ? deleteTaskDetails.fileName : POP_UPS_TRANSLATION.REMOVES
            },
            {
                label: POP_UPS_TRANSLATION.RESOURCE_NAME, value: deleteTaskDetails.resourceName ? deleteTaskDetails.resourceName.placeholder : POP_UPS_TRANSLATION.TASK_NAME_VALUE
            },
            { label: ORDER_TASKS_REPORT.DUE_DATE, value: deleteTaskDetails ? deleteTaskDetails.dueDate : '18/06/2023' },
            { label: POP_UPS_TRANSLATION.STATUS, value: deleteTaskDetails.fileType ? deleteTaskDetails.fileType.placeholder : POP_UPS_TRANSLATION.STATUS_NOT_DONE },
        ]
    }
    return (
        <>
        {
            taskTableData.rows.length ? <Table data={taskTableData} view={view} popupClickHandlers={popupClickHandlers} />
            : <EmptyList name={TASKS}/>
        }
            <div className={showPopUp ? 'popUp__background' : ""}>
                {showPopUp ?
                    <>
                        <div style={{ visibility: currentPopup && currentPopup === 'delete' ? 'visible' : 'hidden' }}>
                            <DeletePopUp
                                handleShowPopUp={handleShowPopUp}
                                data={deleteInfoPopUp}
                                view={view}
                                id={selectedId}
                            />
                        </div>
                        <div style={{ visibility: currentPopup && currentPopup === 'edit' ? 'visible' : 'hidden' }}>
                            <AddTaskPopUp
                                showPopUp={showPopUp}
                                handleShowPopUp={handleShowPopUp}
                                name={POP_UPS_TRANSLATION.EDIT_TASK}
                                process={process}
                                newTask={newTask}
                                view={view}
                                mode='edit'
                            />
                        </div>
                        <div style={{ visibility: currentPopup && currentPopup === 'add' ? 'visible' : 'hidden' }}>
                            <AddTaskPopUp
                                showPopUp={showPopUp}
                                handleShowPopUp={handleShowPopUp}
                                name={POP_UPS_TRANSLATION.ADD_A_TASK}
                                process={process && process}
                                newTask={newTask}
                                view={view}
                            />
                        </div>
                    </> : null}
            </div>
            {
                view === 'desktop' ? <Grid item xs={12} className='fileTab__btnRow'>
                    <Grid item xs={6} className='fileTab__btnRow-right'>
                        <Button data={taskTabBtnsDesktop.save} />
                        &nbsp;
                        &nbsp;
                        <Button data={taskTabBtnsDesktop.cancel} handleClick={() => closePopup()} />
                    </Grid>

                    <Grid item xs={6} className='fileTab__btnRow-left'>
                        <Button
                            data={taskTabBtnsDesktop.task}
                            handleClick={() => {
                                setCurrentPopup('add');
                                handleShowPopUp()
                            }}
                        />
                    </Grid>
                </Grid>
                    : <Grid item xs={12} className='fileTab__btnRow'>
                        <Grid item xs={6} className='fileTab__btnRow-right'>
                            <Button data={taskTabBtnsTablet.save} />
                            &nbsp;
                            &nbsp;
                            <Button data={taskTabBtnsTablet.cancel} handleClick={() => closePopup()} />
                        </Grid>

                        <Grid item xs={6} className='fileTab__btnRow-left'>

                            <Button data={taskTabBtnsTablet.startTimeCount} />
                            &nbsp;
                            &nbsp;
                            <Button
                                data={taskTabBtnsTablet.task}
                                handleClick={() => {
                                    setCurrentPopup('add');
                                    handleShowPopUp()
                                }}
                            />
                        </Grid>
                    </Grid>
            }
        </>
    )
}
