import * as React from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import "./Table.scss";
import CheckListRowNew from './CheckListRowNew/CheckListRowNew';
import { useAdditionalCheckList } from '../../../hooks/useAdditionalCheckLists';
import { useTestsPerFloor } from '../../../hooks/useTestsPerFloor';
import { useDispatch } from 'react-redux';
import {updateSystemChecklist} from '../../../actions/customer/systemActions'
// import CheckListRow from '../../updatePopups/reviewPopup/parts/systemsCheckListSection/parts/checkListRow';



const StyledTableCell = withStyles({
    root: {
        // borderBottom: "1px solid #CACBCC",
        // borderTop: "1px solid #CACBCC",
       
        // border: '1px solid red',
        padding: '0px 10px',
        "&:last-child": {
            borderBottom: "1px solid #CACBCC",
            borderTop: "1px solid #CACBCC",
            borderLeft: "1px solid #CACBCC",
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',

        },
        "&:first-child": {
            borderRight: "1px solid #CACBCC",
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            borderBottom: "1px solid #CACBCC",
            borderTop: "1px solid #CACBCC",

        }
    },
})(TableCell);

const StyledTableHeaderCell = withStyles({
    root: {
        paddingTop: '10px',
        paddingBottom: '10px', 
        borderTop: "1px solid #CACBCC",
        "&:last-child": {
            borderLeft: "1px solid #CACBCC",
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
        },
        "&:first-child": {
            borderRight: "1px solid #CACBCC",
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
        }
    },
})(TableCell);

const StyledTableContainer = withStyles({
    root: {
        border: 'none',
        padding: '0px',
        border: '5px solid red',
        overflowX: "initial",
        boxShadow: 'none'
    }
}) (TableContainer);



const useStyles = makeStyles((theme) => ({
    tableContainer: {
        border: 'none',
        background: 'transparent'
    },
    table: {
        borderCollapse: 'separate',
        borderSpacing: '0px 10px',
        border: 'none',
    },
    tableHead: {
        backgroundColor: '#EEEEEE',
    },
    tableRow: {
        maxHeight: '20px'
    },
    headerCell: {
        fontSize: '14px',
        fontWeight: 800,
        color: '#455768',
        fontFamily: 'Rubik !important',
    },
    bodyCell: {
        fontSize: '14px',
        fontWeight: 400,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        position: 'relative',
        maxWidth: '100px',
        maxHeight: '100px',
        background: '#fff',
    },
    input: {
        marginTop: '2px',
        width: '100%',
        padding: '6px',
        border: '1px solid #CACBCC',
        borderRadius: '5px',
        color: '#CACBCC',
    },
    selectInputContainer: {
        position: 'absolute',
        top: '12px',
        left: '0px'
    }

}));
let inputData = { label: '', placeholder: '', width: '70px', height: '20px', border: '1px dashed #CACBCC', onChange: function () { } };
let selectionInputData = { label: '', placeholder: 'Jakob Baptista', width: '70px', height: '30px', border: 'none', selectOptions: [{ id: 1, name: 'תקין' }, { id: 2, name: 'לא תקין' }, { id: 3, name: 'לא קיים / לא ניתן לבצע בדיקה' }], handleChange: function () { } };
let checkboxInput = { name: '', with: '40px', border: 'none', isBoxShadow: false, background: 'transparent' };

export default function CheckListTable(props) {
    let { data, orderId, process, parentSystemId } = props;
    const classes = useStyles();

    const dispatch = useDispatch()

    // temporarily variable
    let union_check_list = true;
    let actual_system_id = data.actualSystemId;

    const { setRowTitle} = useAdditionalCheckList(orderId, actual_system_id, union_check_list)
    const { getTestsPerFloor} = useTestsPerFloor(actual_system_id)

    function setValueHandler(rowIdx, colIdx, value) {
        dispatch(updateSystemChecklist({rowIdx, colIdx, value}))
    }
    function uploadPhotoHandler() {

    }
    function removeFileHandler() {

    }

    return (
        <>
            {/* // <Paper sx={{ width: '100%', overflow: 'hidden' }}> */}
            <StyledTableContainer component={Paper} style={{ position: "sticky", top: 0, border: 'none' }} className={classes.tableContainer}>
                <Table
                    aria-label="caption table" size='small'
                    className={classes.table}
                    stickyHeader
                >
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            {
                                data.columns.filter(item => item.field !== 'progress')?.map((col, index) => {
                                    return <StyledTableHeaderCell key={index} align="right" className={classes.headerCell}>{col.headerName}</StyledTableHeaderCell>
                                })
                            }
                        </TableRow>
                    </TableHead>
                    {/* </Table>
            </TableContainer>
            <TableContainer style={{ height: "400px", overflow: "auto" }}>
                <Table className={classes.table} style={{ tableLayout: "fixed" }}> */}
                    {
                        data.rows &&

                        <TableBody>
                            {data.rows.map((row, rowIdx) => {
                                let newRow = {};
                                newRow.values = row;
                                return <CheckListRowNew
                                    key={rowIdx}
                                    row={newRow.values}
                                    rowTitle={newRow.row_title}
                                    rowIdx={rowIdx}
                                    setValueHandler={setValueHandler}
                                    uploadPhotoHandler={uploadPhotoHandler}
                                    removeFileHandler={removeFileHandler}
                                    orderId={orderId}
                                    setRowTitle={setRowTitle}
                                    getTestsPerFloor={getTestsPerFloor}
                                />

                        
                            })}
                        </TableBody>
                    }
                </Table>
                {
                    !data.rows && <div className="table__emptyContainer"><p className='table__noItem'>There is no data</p></div>
                }
            </StyledTableContainer>
            {/* // </Paper> */}
        </>
    );
}


{/* else if (typeof (_rowCol.value) == 'object') {
                                                return <StyledTableCell size='small' key={colIdx} component="td" scope="row" align="right" style={{ position: 'relative' }} className={classes.bodyCell}>
                                                    <div className={classes.selectInputContainer}>
                                                        <SelectInput data={selectionInputData} />
                                                    </div>
                                                </StyledTableCell> */}