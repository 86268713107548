import React, { useCallback, useEffect, useState } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useStyles } from '../style'
import CustomizedImageSelect from '../Components/CustomizedImageSelect'
import CustomizedTextInput from '../Components/CustomizedTextInput'
import CustomizedCheckBox from '../Components/CustomizedCheckBox'
import CustomizedEditableCell from '../Components/CustomizedEditableCell'
import OrderInput from '../Components/OrderInput'
import { icons } from '../templates/icons'
import { LAYER_2, LAYER_4, TEST_WITH_OPEN_DOORS_ID, TEST_WITH_CLOSE_DOORS_ID } from '../../../../constants/admin-systems'
import CustomizedSelect from "../Components/CustomizedSelect";
import { api } from "../../../../constants/api-urls";
import { PROCESS_GET_BY_FACTORY_ID_REQUEST } from "../../../../actions/types";
import { useDispatch, useSelector } from "react-redux";
import { getProcessByFactory } from "../../../../actions/gantt_template_actions";
import { generalGetRequest } from '../../../../functions/api/general'



const TestsList = (props) => {
  const classes = useStyles()
  const { data, deleteTestHandler, changeValuesHandler, showStopperOptions, inputTypes, setInputTypes, isAdditionalTest, layer, isFactoryServiceType, factoryId } = props
  const layerTwo = LAYER_2 === layer
  const layerFour = LAYER_4 === layer

  const dispatch = useDispatch();
  const processList = useSelector((state) => state.ganttTemplate.listProcesses)
  const [previousLayerSystemsList, setPreviousLayerSystemsList] = useState([])
  // const systemsList = useSelector((state) => state.systemsList.)


  const addToGroupedByParentHandler = useCallback((rowIdx) => (value) => {
    // set that test is for layer 2 link to tests from layer 4
    changeValuesHandler(rowIdx, 'group_by_parent')(value)
    // add default property test_union === false for grouped tests from layer 4 for layer 2 link
    changeValuesHandler(rowIdx, 'test_union')(false)
  }, [changeValuesHandler])


  useEffect(() => {
    if (!isFactoryServiceType && factoryId) {
      dispatch(getProcessByFactory(factoryId));
      generalGetRequest(`/admin/systems/get-systems-by-layer?layer=${layer-1}&factory_id=${factoryId}`).then((res) => {
        if (res.ok) {
          const result = res.result.map((row) => {
            return {
              ...row,
              name: row.description,
            }
          })
          setPreviousLayerSystemsList(result)
        }
      }).catch((err) => {
        console.log("Error", err)
      })
    }
  }, [factoryId])

  return data?.map((row, rowIdx) => {
    const values = row.values
    const testFromLayerFour = row.group_by_parent
    const addLinkToTests = row.test_union
    const testPerFloorId = row.test_per_floor_id
    return <TableRow key={row._id}>
      {
        isFactoryServiceType &&  <CustomizedCheckBox
          value={testPerFloorId === TEST_WITH_OPEN_DOORS_ID}
          changeHandler={changeValuesHandler(rowIdx, TEST_WITH_OPEN_DOORS_ID)}
        />
      }
      {
        isFactoryServiceType && <CustomizedCheckBox
          value={testPerFloorId === TEST_WITH_CLOSE_DOORS_ID}
          changeHandler={changeValuesHandler(rowIdx, TEST_WITH_CLOSE_DOORS_ID)}
        />
      }
      {
        // if layer is 2 and test is from layer 4 add checkbox for tagging this be added to actual check lists 
        // and on click redirect to new checklist with the same tests as this test from child systems from layer 4 relatted to this system 
        (isFactoryServiceType && layerTwo) ? (testFromLayerFour ? <CustomizedCheckBox
          value={addLinkToTests}
          changeHandler={changeValuesHandler(rowIdx, 'test_union')}
        /> : <TableCell></TableCell>) : null
      }
      {
        //  if system layer is 4 add checkbox for tagging tests be added into layer 2 systems as option to be selected by test name
        (isFactoryServiceType && layerFour) ? <CustomizedCheckBox
          value={row?.group_by_parent}
          changeHandler={addToGroupedByParentHandler(rowIdx)}
          disabled={isAdditionalTest}
        /> : null
      }
      {
        isFactoryServiceType && <CustomizedCheckBox
        value={row.half_year_test}
        changeHandler={changeValuesHandler(rowIdx, 'half_year_test')}
      />
      }
      
      <TableCell>
        <DeleteForeverIcon className={classes.icons} onClick={() => deleteTestHandler(row._id)} />
      </TableCell>
      {
        !isFactoryServiceType && factoryId &&
        <>
          <CustomizedSelect
            options = {processList && processList}
            value = {row.selected_process}
            property = "name"
            name="name"
            changeHandler = {changeValuesHandler(rowIdx, 'selected_process')} 
          />
        </>
      }
      {
        !isFactoryServiceType && factoryId &&
        <>
          <CustomizedSelect
            options = {previousLayerSystemsList && previousLayerSystemsList}
            value = {row.selected_upper_layer_system}
            property = "name"
            name="name"
            changeHandler = {changeValuesHandler(rowIdx, 'selected_upper_layer_system')} 
          />
        </>
      }
      <OrderInput value={row.order} changeHandler={changeValuesHandler(rowIdx, 'order')} />
      {
        isFactoryServiceType &&  <CustomizedCheckBox
          value={isAdditionalTest}
          changeHandler={() => ({})}
        />
      }
      <CustomizedImageSelect
        options={icons}
        value={row.icon}
        changeHandler={changeValuesHandler(rowIdx, 'icon')}
      />
      <CustomizedTextInput
        value={row.row_title}
        changeHandler={changeValuesHandler(rowIdx, 'row_title')}
      />
      {/* <CustomizedShowStopperSelect value={row.show_stopper} options = {showStopperOptions} changeHandler={changeValuesHandler(rowIdx, SHOW_STOPPER)} /> */}
      {values.map((i, colIdx) => i.typeOfInput === 'checkbox' ?
        <CustomizedCheckBox
          value={i.value}
          changeHandler={changeValuesHandler(rowIdx, colIdx)}
          key={colIdx}
        /> :
        <CustomizedEditableCell
          value={i.typeOfInput}
          changeCheckListValueHandler={changeValuesHandler(rowIdx, colIdx)}
          inputTypes={inputTypes}
          setInputTypes={setInputTypes}
          key={colIdx}
          showStoppers={showStopperOptions}
        />
      )}
    </TableRow>
  })
}

export default TestsList

