import React, { useCallback, useEffect, useState } from "react";
import { executeSaturdayScript } from "../../../functions/api/admin";
import "../../../sass/developers/_developer.scss";


const DeveloperTools = () => {

  const [satrudayScript, setSaturdayScript] = useState({
    flag: false,
    response: null
  });

  const handleSaturdayScript = () => {
    setSaturdayScript(pre => ({
      flag: true,
    }))
    executeSaturdayScript().then(res => {

      setSaturdayScript(pre => ({
        flag: false,
        response: res?.result
      }))
    });
  }

  let {flag, response} = satrudayScript;
  return (
    <div className="developer-container">     
      <div className="list-section">
        <div className="saturday-script-btn" onClick={handleSaturdayScript}>
          <button>execute saturday script</button>
        </div>
      </div>
      <div className="view-section">
        {
          flag && <div>
            Saturday Script is Running...
          </div>
        }
        {
          !flag && response && <div>
            <div>{"Total Document Moved: "}{response.moved_doc}</div>
            <div>{"Factory Sucess: "}
            <div>
              {
                response.Movedfactory.map(Object.entries).map(([key, value]) => {
                  return <div>
                    {key}: {value}
                  </div>
                })
              }
            </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default DeveloperTools;
