import { Typography } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import Loader from '../../LoaderNew/Loader'
import SideBar from '../../SideBar/SideBar'
import { DEPARTMENT, WEEK_WORKLOAD_CHART, MONTH_WORKLOAD_CHART, YEAR_WORKLOAD_CHART } from '../../../constants/translations/order-tasks-report'
import Chart from './Parts/Chart/Chart'
import CustomizedSelect from './Parts/Select'
import ReportDatePicker from './Parts/DatePicker'
import { MONTH_REPORT } from '../../../constants'
import {useChartReport, useDepartmentsOptions, useReportType, useWeekReportType} from '../../../hooks/chartReport'


function getReport(reportType, week_report){
  switch(reportType){
    case 'month':
      return MONTH_WORKLOAD_CHART;
    case 'week':
      return YEAR_WORKLOAD_CHART;
    default:
      if(week_report){
        return WEEK_WORKLOAD_CHART;
      } else{
        return YEAR_WORKLOAD_CHART;
      }

  } 
}

const WorkloadChart = () => {
  const reportType = useReportType()
  const week_report_type = useWeekReportType()

  const departmentsOptions = useDepartmentsOptions()
  
  const [selectedDepartmentId, setSelectedDepartmentId] = useState('')

  const {
      charts, 
      isLoading, 
      startDate, 
      increaseStartDateHandler, 
      decreaseStartDateHandler 
                                } = useChartReport(reportType, selectedDepartmentId, week_report_type)
  
  const changeDepartmentHandler = (e) => setSelectedDepartmentId(e.target.value)
  

  return <div className="backlogs-reports">
    <SideBar />
      <section className="backlogs-reports__main">
          <header>
            <Typography variant = 'h5'>{getReport(reportType, week_report_type)}</Typography>
            <CustomizedSelect
              value = {selectedDepartmentId}
              label = {DEPARTMENT}
              changeHandler = {changeDepartmentHandler}
              options = {departmentsOptions}
            />
            <ReportDatePicker
              reportType = {reportType}
              increaseStartDateHandler = {increaseStartDateHandler}
              decreaseStartDateHandler = {decreaseStartDateHandler}
              startDate = {startDate}
            />
          </header>
      {isLoading ?  
        <Loader/>
        :
        <Grid className="backlog-report-scroll">
            {charts ? charts.map(chartData=><Chart 
                                              key = {chartData.sub_department_id} 
                                              chartData = { chartData }/>) : null
            }
        </Grid>
      }
      </section>
  </div>
}



export default WorkloadChart