import React, { useCallback, useEffect, useState } from 'react';
import Table from '../../Table/Table2';
import AddFilePopUp from '../../PopUps/AddFilePopUp/AddFilePopUp';
import Button from '../../Buttons/Button';
import OpenedFilePopUp from '../../PopUps/OpenedFilePopUp/OpenedFilePopUp';
import DeletePopUp from '../../PopUps/DeletePopUp/DeletePopUp';
import { useDispatch, useSelector } from 'react-redux';
import { fileActionCreator } from '../../../../actions/customer/fileActions';
import { TABLET_VIEW_TRANSLATION } from '../../../../constants/translations/customer/tablet/tabletView';
import { POP_UPS_TRANSLATION } from '../../../../constants/translations/customer/PopUps/PopUps'
import AddFilePopUpTablet from '../../TabletView/PopUps/AddFile/AddFilePopUp';
import { desktopFilesList as filesTableData } from '../../../../constants/staticeData/filesList';
import { deleteAttachment } from '../../../../functions/api/customer-page';
import { setCustomerData } from '../../../../actions/customers_table_actions';


const fileBtn = {
    name: TABLET_VIEW_TRANSLATION.FILE,
    width: '15%',
    height: '52px',
    color: '#fff',
    backgroundColor: "#186315",
    fontSize: '16px',
    fontWeight: 800,
    icon: true,
    iconContainer: true,
    border: false,
    buttonName: 'AddIcon'
}

function formateFilesDataIntoTableFormate(files, customerName){
  const rows = [];
   // { id: 1, fileName: 'A Corem ipsum dolor sit amet, consectetur adipiscing, jahsbdfjasbfasl, jhasbks', uploadedBy: 'Jon', uploadDate: '15/06/2023', fileType: { label: '', placeholder: 'Not Done', width: '100px', height: '30px', border: 'none' } },
 
   const fileTypeSelectionOptions = ["png", "jpeg", "pdf", "text"]
  for (let row of files) {
    let _row = {
      id: row['_id'],
      fileName: row['original_name'],
      uploadedBy: customerName,
      uploadDate: '12-12-23',
      // fileType: { label: '', placeholder: row['type'], value: row['type'],  width: '100px', height: '30px', border: 'none' },
      fileType: { placeholder: row['type'], value: row['type'], selectionOptions: fileTypeSelectionOptions }, 
      contactPhoneNumber: row['order_number']
    };
    rows.push(_row)
  }

  return rows
}

// select file to open
function getFilePathFormSelectedFileId(customer, currentFileId){
  const filePath = customer['attachments'].length && customer['attachments'].find((file) => file._id === currentFileId)?.path
  return filePath;
 }

export default function FileTab(props) {
  const { showPopUp, handleShowPopUp, view, files, customer } = props;
  const dispatch = useDispatch();
  // const filesTableData = useSelector((state) => state.files.filesDesktop);
  
  const [actionState, setActionState] = useState('');
  const [showFilePopUp, setShowFilePopUp] = useState(false);
  const [label, setLabel] = useState('');
  const [currentPopup, setCurrentPopup] = useState('add');
  const [currentFileId, setCurrentFileId] = useState(null);

  const customerName = customer?.name || customer?.contact_name || ''
  const customerId = customer?._id;
  const formattedFilesTableDataRows = formateFilesDataIntoTableFormate(files, customerName);
  filesTableData.rows = formattedFilesTableDataRows;




  function onClickHandler(label) {

    setShowFilePopUp(true);
    setLabel(label);
  }

  const popupClickHandlers = {
    'Open File': (rowID) => {
      setCurrentPopup('open');
      handleShowPopUp();
      setCurrentFileId(rowID)
    },
    'Edit File': (rowID) => {
      setCurrentPopup('edit');
      handleShowPopUp();
      setCurrentFileId(rowID)
    },
    'Delete File': (rowID) => {
      setCurrentPopup('delete');
      handleShowPopUp();
      setCurrentFileId(rowID)
    },
  }

  // delete file 
  const deleteFileHandler = useCallback(async () => {
    const { ok, result } = await deleteAttachment(customerId, currentFileId)

    if (ok && result) {
      dispatch(setCustomerData(result))
      handleShowPopUp();
    }
  }, [currentFileId])

  return (
    <div>
      <Table data={{ ...filesTableData, actionClickHandler: onClickHandler }} popupClickHandlers={popupClickHandlers} />
      <div className={showPopUp ? 'popUp__background' : ""}>
        {
          showPopUp ? <>
            <div style={{ visibility: currentPopup && currentPopup === 'edit' ? 'visible' : 'hidden' }}>
              <AddFilePopUp
                showPopUp={showPopUp}
                handleShowPopUp={handleShowPopUp}
                view="desktop"
                name={POP_UPS_TRANSLATION.EDIT_FILE}
                customerId={customer?._id}
                customerName={customer?.name}
                currentFileId={currentFileId}
                mode={'edit'}
              />
              </div>
            <div style={{ visibility: currentPopup && currentPopup === 'delete' ? 'visible' : 'hidden' }}>
              <DeletePopUp showPopUp={showPopUp} handleDelete={deleteFileHandler} closePopup={handleShowPopUp}/>
            </div>
            <div style={{ visibility: currentPopup && currentPopup === 'open' ? 'visible' : 'hidden' }}>
              <OpenedFilePopUp
                showPopUp={showPopUp}
                handleShowPopUp={handleShowPopUp}
                attachmentFile= {getFilePathFormSelectedFileId(customer, currentFileId)}
              />
            </div>
            <div style={{ visibility: currentPopup && currentPopup === 'add' ? 'visible' : 'hidden' }}>
              {/* {view === 'tablet' ? <AddFilePopUpTablet showPopUp={showPopUp}
                handleShowPopUp={handleShowPopUp}
                name={POP_UPS_TRANSLATION.ADD_A_FILE}
                view='tablet' 
                /> : */}
              <AddFilePopUp
                showPopUp={showPopUp}
                handleShowPopUp={handleShowPopUp}
                view="desktop"
                name={POP_UPS_TRANSLATION.ADD_A_FILE}
                customerId={customer?._id}
                customerName={customer?.name}
              />
                {/* } */}
            </div>

          </> : null
         
        }
      </div>
      <div className='desktopView desktopTab__footer'>
        <div className='desktopTab__footer__btnContainer'>
          <Button data={fileBtn} handleClick={() => handleShowPopUp()} />
        </div>
      </div>

    </div>
  )
}
